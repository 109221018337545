
import { defineComponent, PropType } from 'vue'
import { Voice } from '@/modules/ciso/model/voices.model'
import { useRouter } from 'vue-router'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'

export default defineComponent({
  name: 'VoicesDetailsTable',
  props: {
    voices: {
      type: Array as PropType<Voice[]>,
      required: true,
    },
  },
  setup() {
    const router = useRouter()

    const navigateToVoice = (id: string) => {
      router.push({ name: SlowpokeRoute.EditVoice, params: { id } })
    }

    return { navigateToVoice }
  },
})
