import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoBack = _resolveComponent("GoBack")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_SlowpokeClonedVoiceEditor = _resolveComponent("SlowpokeClonedVoiceEditor")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_GoBack, {
        to: { name: _ctx.SlowpokeRoute.ClonedVoices }
      }, null, 8, ["to"]),
      _createTextVNode(" Voice: " + _toDisplayString(_ctx.data.voice?.data?.name), 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_DataLoader, {
        "is-loading": _ctx.isLoading(_ctx.data.voice)
      }, null, 8, ["is-loading"]),
      _createVNode(_component_DataLoadingError, {
        subject: _ctx.data.voice
      }, null, 8, ["subject"]),
      (_ctx.isLoaded(_ctx.data.voice))
        ? (_openBlock(), _createBlock(_component_SlowpokeClonedVoiceEditor, {
            key: 0,
            voice: _ctx.data.voice.data
          }, null, 8, ["voice"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}