
import { defineComponent, reactive } from 'vue'
import { Voice } from '@/modules/slowpoke/model/voices.model'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import Panel from '@/components/UI/Panel.vue'
import { getAllVoices } from '@/modules/slowpoke/api/voices.api'
import VoicesDetailsTable from '@/modules/slowpoke/components/voice/VoiceDetailsTable.vue'

export default defineComponent({
  name: 'SlowpokeVoicePicker',
  components: { VoicesDetailsTable, Panel, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      availableVoices: LOADING_STATE as LoadingState<Voice[]>,
    })

    const loadAvailableVoices = async () => {
      try {
        data.availableVoices = LOADING_STATE
        data.availableVoices = loaded(await getAllVoices())
      } catch (e) {
        data.availableVoices = errored(e)
      }
    }

    loadAvailableVoices()

    return {
      data,
      isLoaded,
      isLoading,
      isErrored,
    }
  },
})
