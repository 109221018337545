
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import { Voice, validateVoice } from '@/modules/slowpoke/model/voices.model'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormErrored,
  isFormLoading,
  isFormSuccess,
} from '@/utils/form-state'
import { deactivateVoice, upsertVoice } from '@/modules/slowpoke/api/voices.api'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { useRouter } from 'vue-router'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'
import { CISORoute } from '@/modules/ciso/routes'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import { ApiError } from '@/api/_shared'
import { useToaster } from '@/composables/useToaster'

export default defineComponent({
  name: 'SlowpokeVoiceEditor',
  components: {
    PanelSubheading,
    FormStateAlert,
  },
  props: {
    voice: {
      type: Object as PropType<Voice>,
      required: true,
    },
    isNewVoice: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      formState: FORM_INITIAL_STATE as FormState,
      showDeleteModal: false,
    })

    const localVoice = computed({
      get: () => props.voice,
      set: (value: Voice) => emit('update:voice', value),
    })

    const router = useRouter()
    const toaster = useToaster()

    watch(
      () => props.voice,
      () => {
        window.scrollTo({ left: 0, top: 0 })
      }
    )

    const actionHandler = async (actionFn: () => void) => {
      if (data.formState === FORM_LOADING_STATE) {
        return
      }

      window.scrollTo({ left: 0, top: 0 })

      data.formState = FORM_LOADING_STATE

      return actionFn()
    }

    const saveVoiceHandler = async () => {
      try {
        const voiceValidation = validateVoice(localVoice.value)

        if (voiceValidation !== true) {
          data.formState = formError('The form contains errors:', voiceValidation)
          return
        }

        localVoice.value = await upsertVoice(localVoice.value)
        data.formState = formSuccess(`Voice "${localVoice.value.name}" has been successfully saved.`)
      } catch (e) {
        data.formState = formError(
          `Voice "${localVoice.value.name || localVoice.value.id}" could not be saved. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const deactivateVoiceHandler = async () => {
      try {
        await deactivateVoice(localVoice.value)

        const message = `Voice "${localVoice.value.id}" has been successfully deactivated.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({ name: SlowpokeRoute.Voices })
      } catch (e) {
        data.formState = formError(
          `Voice "${localVoice.value.name || localVoice.value.id}" could not be deactivated. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const editInCiso = () => {
      router.push({ name: CISORoute.EditVoice, params: { id: localVoice.value.id } })
    }

    return {
      data,
      localVoice,
      actionHandler,
      saveVoiceHandler,
      deleteVoiceHandler: deactivateVoiceHandler,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      toaster,
      editInCiso,
    }
  },
})
