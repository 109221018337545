import { VoiceLabels } from '@/modules/ciso/model/voice-label.model'

export class Voice {
  id?: number
  name: string
  labels: VoiceLabels
  aliasName: string
  description?: string
  keyTech?: string
  keyModel?: string
  keyId?: string
  rating?: number
  speechKey?: string
  speechSpkEmb?: number[]
  previewUrl: string | null
  createdAt: Date
  updatedAt: Date
  active: boolean

  constructor(doc: Partial<Voice>) {
    this.id = doc.id || undefined
    this.name = doc.name || ''
    this.labels = doc.labels || []
    this.description = doc.description || ''
    this.aliasName = doc.aliasName || ''
    this.keyTech = doc.keyTech || ''
    this.keyModel = doc.keyModel || ''
    this.keyId = doc.keyId || ''
    this.rating = doc.rating || undefined
    this.speechKey = doc.speechKey || ''
    this.speechSpkEmb = doc.speechSpkEmb || undefined
    this.previewUrl = doc.previewUrl || null
    this.updatedAt = doc.updatedAt ? new Date(doc.updatedAt) : new Date()
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
    this.active = Boolean(doc.active)
  }
}

export function validateVoice(voice: Voice): true | string[] {
  const errors: string[] = []

  return errors.length > 0 ? errors : true
}
