export class ClonedVoice {
  id?: number
  uuid?: string
  clientID: number
  name: string
  previewUrls: string[]
  imageUrl: string | null
  inputHash: string
  createdAt: Date
  updatedAt: Date

  constructor(doc: Partial<ClonedVoice>) {
    this.id = doc.id || undefined
    this.uuid = doc.uuid || undefined
    this.clientID = doc.clientID || 0
    this.name = doc.name || ''
    this.previewUrls = doc.previewUrls || []
    this.imageUrl = doc.imageUrl || null
    this.inputHash = doc.inputHash || ''
    this.updatedAt = doc.updatedAt ? new Date(doc.updatedAt) : new Date()
    this.createdAt = doc.createdAt ? new Date(doc.createdAt) : new Date()
  }
}
