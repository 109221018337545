import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3caeb642"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelSubheading = _resolveComponent("PanelSubheading")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_form = _resolveComponent("va-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_va_form, { class: "mb-5" }, {
      default: _withCtx(() => [
        _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
          default: _withCtx(() => [
            _createTextVNode("Voice data")
          ]),
          _: 1
        }),
        (_ctx.voice.imageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "voice-img mb-3",
              src: _ctx.voice.imageUrl,
              alt: ""
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        _createVNode(_component_va_input, {
          bordered: "",
          class: "mb-3",
          label: "ID",
          "model-value": String(_ctx.voice.id),
          disabled: ""
        }, null, 8, ["model-value"]),
        _createVNode(_component_va_input, {
          bordered: "",
          class: "mb-3",
          label: "UUID",
          "model-value": String(_ctx.voice.uuid),
          disabled: ""
        }, null, 8, ["model-value"]),
        _createVNode(_component_va_input, {
          bordered: "",
          class: "mb-3",
          label: "ClientID",
          "model-value": String(_ctx.voice.clientID),
          disabled: ""
        }, null, 8, ["model-value"]),
        _createVNode(_component_va_input, {
          bordered: "",
          class: "mb-3",
          label: "Name",
          "model-value": _ctx.voice.name,
          disabled: ""
        }, null, 8, ["model-value"]),
        _createVNode(_component_va_input, {
          bordered: "",
          class: "mb-3",
          label: "Input hash",
          "model-value": _ctx.voice.inputHash,
          disabled: ""
        }, null, 8, ["model-value"]),
        _createVNode(_component_va_input, {
          bordered: "",
          class: "mb-3",
          label: "Created at",
          "model-value": _ctx.voice.createdAt.toLocaleString(),
          disabled: ""
        }, null, 8, ["model-value"]),
        (_ctx.voice.previewUrls.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
                default: _withCtx(() => [
                  _createTextVNode("Voice previews")
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.previews, (preview) => {
                return (_openBlock(), _createElementBlock("audio", {
                  class: "voice-preview mb-2",
                  controls: "",
                  key: preview,
                  src: preview
                }, null, 8, _hoisted_3))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}