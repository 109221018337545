import { getAuthHeaders, getRequestIdHeaders, simpleAuthorizedQuery } from '@/utils/fetch'
import { Voice } from '@/modules/slowpoke/model/voices.model'
import axios from 'axios'
import { apiErrorCatcher } from '@/api/_shared'
import { slowpokeApiUrl } from '@/modules/slowpoke/api/_shared'
import { ClonedVoice } from '@/modules/slowpoke/model/cloned-voices.model'

export async function getVoice(id: string): Promise<Voice> {
  const res = await simpleAuthorizedQuery<Voice>(slowpokeApiUrl(`admin/voices/${id}`))

  return new Voice(res)
}

export async function getAllVoices(): Promise<Voice[]> {
  const res = await simpleAuthorizedQuery<Voice[]>(slowpokeApiUrl('admin/voices'))

  return (res || []).map((v) => new Voice(v))
}

export async function getClonedVoice(id: string): Promise<ClonedVoice> {
  const res = await simpleAuthorizedQuery<ClonedVoice>(slowpokeApiUrl(`admin/cloned-voices/${id}`))

  return new ClonedVoice(res)
}

export async function getAllClonedVoices(): Promise<ClonedVoice[]> {
  const res = await simpleAuthorizedQuery<Voice[]>(slowpokeApiUrl('admin/cloned-voices'))

  return (res || []).map((v) => new ClonedVoice(v))
}

export async function getClonedVoicePreview(previewURL: string): Promise<Blob> {
  const rawHeaders = getAuthHeaders()
  const headers = new Headers()
  for (const headerName in rawHeaders) {
    headers.set(headerName, rawHeaders[headerName])
  }

  const res = await fetch(previewURL, {
    headers,
  })

  return res.blob()
}

export async function upsertVoice(voice: Voice): Promise<Voice> {
  const patch: Partial<Voice> = {
    ...voice,
  }

  return axios
    .post(slowpokeApiUrl(`admin/voices`), patch, {
      headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
    })
    .catch(apiErrorCatcher)
    .then((r) => r.data)
    .then((data) => new Voice(data))
}

export async function deactivateVoice(voice: Voice): Promise<unknown> {
  return axios
    .delete(slowpokeApiUrl(`admin/voices/${voice.id}`), {
      headers: { ...getRequestIdHeaders(), ...getAuthHeaders() },
    })
    .catch(apiErrorCatcher)
}
