
import { defineComponent, onUnmounted, PropType, reactive } from 'vue'
import { isFormErrored, isFormLoading, isFormSuccess } from '@/utils/form-state'
import { useToaster } from '@/composables/useToaster'
import { ClonedVoice } from '@/modules/slowpoke/model/cloned-voices.model'
import { slowpokeApiUrl } from '@/modules/slowpoke/api/_shared'
import { getClonedVoicePreview } from '@/modules/slowpoke/api/voices.api'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'

export default defineComponent({
  name: 'SlowpokeClonedVoiceEditor',
  methods: { slowpokeApiUrl },
  components: { PanelSubheading },
  props: {
    voice: {
      type: Object as PropType<ClonedVoice>,
      required: true,
    },
  },
  setup(props) {
    const data = reactive({
      previews: [] as string[],
    })
    const toaster = useToaster()

    const fetchPreviews = async () => {
      data.previews = []

      for (const preview of props.voice.previewUrls) {
        data.previews.push(URL.createObjectURL(await getClonedVoicePreview(preview)))
      }
    }

    fetchPreviews()

    onUnmounted(() => {
      for (const preview of data.previews) {
        URL.revokeObjectURL(preview)
      }
    })

    return {
      data,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      toaster,
    }
  },
})
