import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormStateAlert = _resolveComponent("FormStateAlert")!
  const _component_PanelSubheading = _resolveComponent("PanelSubheading")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_form = _resolveComponent("va-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FormStateAlert, {
      "form-state": _ctx.data.formState
    }, null, 8, ["form-state"]),
    _createElementVNode("div", null, [
      _createVNode(_component_va_form, { class: "mb-5" }, {
        default: _withCtx(() => [
          _createVNode(_component_PanelSubheading, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createTextVNode("Slowpoke status")
            ]),
            _: 1
          }),
          _createElementVNode("span", {
            class: _normalizeClass(["status", { active: _ctx.localVoice.active }])
          }, _toDisplayString(_ctx.localVoice.active ? 'active' : 'not active'), 3),
          _createVNode(_component_PanelSubheading, { class: "mb-3 mt-4" }, {
            default: _withCtx(() => [
              _createTextVNode("CISO data")
            ]),
            _: 1
          }),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "ID",
            modelValue: _ctx.localVoice.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localVoice.id) = $event)),
            disabled: ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Name",
            modelValue: _ctx.localVoice.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localVoice.name) = $event)),
            disabled: ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Tech key",
            modelValue: _ctx.localVoice.keyTech,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localVoice.keyTech) = $event)),
            disabled: ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Model key",
            modelValue: _ctx.localVoice.keyModel,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localVoice.keyModel) = $event)),
            disabled: ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "ID key",
            modelValue: _ctx.localVoice.keyId,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localVoice.keyId) = $event)),
            disabled: ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Rating",
            modelValue: _ctx.localVoice.rating,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localVoice.rating) = $event)),
            disabled: ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_button, {
            class: "mr-2",
            onClick: _ctx.editInCiso,
            disabled: _ctx.isFormLoading(_ctx.data.formState),
            color: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Edit this voice in CISO ")
            ]),
            _: 1
          }, 8, ["onClick", "disabled"]),
          _createVNode(_component_PanelSubheading, { class: "mb-3 mt-4" }, {
            default: _withCtx(() => [
              _createTextVNode("Slowpoke data")
            ]),
            _: 1
          }),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Alias name",
            modelValue: _ctx.localVoice.aliasName,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localVoice.aliasName) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Description",
            modelValue: _ctx.localVoice.description,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localVoice.description) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_button, {
            class: "mr-2",
            onClick: _cache[8] || (_cache[8] = () => _ctx.actionHandler(_ctx.saveVoiceHandler)),
            disabled: _ctx.isFormLoading(_ctx.data.formState),
            color: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.localVoice.active ? 'Save' : 'Activate'), 1)
            ]),
            _: 1
          }, 8, ["disabled"]),
          (_ctx.localVoice.active)
            ? (_openBlock(), _createBlock(_component_va_button, {
                key: 0,
                class: "mr-2",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.data.showDeleteModal = true)),
                color: "danger",
                disabled: _ctx.isFormLoading(_ctx.data.formState)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Deactivate ")
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_va_modal, {
        modelValue: _ctx.data.showDeleteModal,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.data.showDeleteModal) = $event)),
        title: "Confirm",
        message: "Are you sure you want to deactivate this voice?",
        onOk: _cache[11] || (_cache[11] = () => _ctx.actionHandler(_ctx.deleteVoiceHandler))
      }, null, 8, ["modelValue"])
    ])
  ], 64))
}