
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import { ClonedVoice } from '@/modules/slowpoke/model/cloned-voices.model'

export default defineComponent({
  name: 'ClonedVoicesDetailsTable',
  props: {
    voices: {
      type: Array as PropType<ClonedVoice[]>,
      required: true,
    },
  },
  setup() {
    const router = useRouter()

    const navigateToVoice = (id: string) => {
      router.push({ name: SlowpokeRoute.EditClonedVoice, params: { id } })
    }

    return { navigateToVoice }
  },
})
